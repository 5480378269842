export const ROLE_ADMINISTRATOR = '1'
export const ROLE_RIGHTSLINE_PRODUCT_GATE= '2'
export const ROLE_BULK_DATE_MATH_TOOL = '3'
export const ROLE_AIR_DATE_INGESTION_TOOL = '4'
export const ROLE_WON_ERROR_REPORT = '5'
export const ROLE_APOLLO_ERROR_REPORT = '6'
export const ROLE_JOB_TRACKER = '7'
export const ROLE_LEGAL_ADMIN = '8'
export const ROLE_LEGAL_USER = '9'
export const ROLE_DEAL_VERSIONING = '10'
export const ROLE_PRODUCT_COMMENTS_ADMIN = '11'
export const ROLE_TAP_USER = '12'
export const ROLE_RIGHTSETS_USER = '13'
export const ROLE_RIGHTSETS_ASSOCIATE_USER = '14'
export const ROLE_PMX_USER = '15'
export const ROLE_TAP_ADMIN = '16'
export const VERSION_NUMBER = 'V:082724B1'