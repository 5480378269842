import React, {Fragment} from 'react';
import { useCallback } from 'react';
import {
    Link, SimpleForm, required, Edit, Create, Toolbar, SaveButton,
    TextInput, NumberInput, useNotify, useRedirect,
    ArrayInput, SimpleFormIterator, Button,
    FormDataConsumer, ReferenceInput, SelectInput, BooleanInput
} from 'react-admin';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddBoxRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";

const EditWindowingSequenceBreadCrumb = ({ record }) => {
  return  <div className="breadcrumbs"><Link to={"/windowSequence"}>Window Sequences</Link> &nbsp;&gt;{record && record.id > 0 ? ` Edit Windowing Sequence: ${record.windowSequenceName} (${record.id})` : ' Create'}</div>;
};

export const durationUnits = [
    {id : '', value: '', name: ''},
    {id : 'Days', value: 'Days', name: 'Days'},
    {id : 'Weeks', value: 'Weeks', name: 'Weeks'},
    {id : 'Months', value: 'Months', name: 'Months'},
    {id : 'Years', value: 'Years', name: 'Years'},
];

export const windowStartDates =  [
    {id : '0', value: 'Super_Premium_EHVL_Start_Date', name: 'Super Premium EHVL Start Date'},
    {id : '1', value: 'Premium_EHVL_Start_Date', name: 'Premium EHVL Start Date'},
    {id : '2', value: 'New_Release_EHVL_Start_Date', name: 'New Release EHVL Start Date'},
    {id : '3', value: 'New_to_Catalog_EHVL_Start_Date', name: 'New to Catalog EHVL Start Date'},
    {id : '4', value: 'Catalog_EHVL_Start_Date', name: 'Catalog EHVL Start Date'},
    {id : '5', value: 'TVOD_Super_Premium_Start_Date', name: 'TVOD Super Premium Start Date'},
    {id : '6', value: 'TVOD_Premium_Start_Date', name: 'TVOD Premium Start Date'},
    {id : '7', value: 'TVOD_Current_Start_Date', name: 'TVOD Current Start Date'},
    {id : '8', value: 'TVOD_Library_Start_Date', name: 'TVOD Library Start Date'}

];

const requiredIfNonPreviousWindowPlusOne = (scopedFormData, formData, index) => {
    return (value, allValues) => {
        const record = allValues.windows[index];
        if(scopedFormData === undefined)
            return undefined;
        const message =
            "Required";
        return (record.previousWindowPlusOneStart === false ) && (value === undefined || value === null || value === '') ? message : undefined;
    };
};

const sameWindowStartDateOnDiffWindow = (scopedFormData, formData, index) => {
    return (value, allValues) => {
        const record = allValues.windows[index];
        if(scopedFormData === undefined)
            return undefined;
        if(record.previousWindowPlusOneStart === true) {
            return undefined
        }
        if(record.previousWindowPlusOneStart === false && scopedFormData.previousWindowPlusOneStart === true) {
            return undefined
        }

        const message =
            "Multiple Windows in a Window Sequence cannot have the same Window Start Date";

        const isWinStartDateAlreadySelectedDiffWin =
            allValues.windows
                .filter(((win) => win !== null && win !== undefined))
                .filter(((win) => win.windowOrder !== index))
                .find(((win) => win.windowStartDate === value))
        return isWinStartDateAlreadySelectedDiffWin !== undefined ? message: undefined;
    };
}

const getWindowArrayIndex = (getSource) => {
    const regex = /windows\[(\d+)\]\./;
    const match = getSource.match(regex);
    return parseInt(match[1]);
}

class WindowingSequenceForm extends React.Component {
  render() {
    const validateSpecialCharacters = (name) => {
        const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/
        if (specialChars.test(name)) {
            return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
        }
        return undefined;
    }

    return (
        <Fragment>
          <Box display="flex" style={{ width: '98%', paddingLeft: '1%' }}>
            <EditWindowingSequenceBreadCrumb {...this.props} />
          </Box>
          <Box display="flex"style={{ width: '98%', paddingLeft: '1%', float: 'left' }} id="WindowsArray">
            <Box display="flex" justifyContent="center" style={{ width: '28%', paddingLeft: '1%' }}>
              <TextInput options={{ fullWidth: true }} label="Windowing Sequence Name" source="windowSequenceName" validate={[required("Windowing Sequence Name cannot be blank"), validateSpecialCharacters]}  />
            </Box>
            <Box display="flex" justifyContent="center" style={{ width: '28%', paddingLeft: '1%' }}>
                <SelectInput source="status" choices={[
                    { id: 'ACTIVE', name: 'ACTIVE' },
                    { id: 'INACTIVE', name: 'INACTIVE' }
                ]} validate={required("Status is required")} />
            </Box>

          </Box>

        </Fragment>
    )
  }
}
const EditWindowingSequenceTitle = ({ record }) => {
 return <span>{record && record.id > 0 ? `Edit Window Sequence ${record.windowSequenceName} (${record.id})` : 'Create Windowing Sequence'}</span>;
};
const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);
//<DeleteWithConfirmButton label="Deactivate" style={{ float: 'right', marginLeft: 'auto' }} confirmTitle="Deactivate Window Sequence" confirmContent="Are you sure you wish to deactivate this windowing sequence?" />
export const WindowingSequenceEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { trigger } = useForm();

  const save = useCallback(
       async (values) => {
           try {
              document.getElementById("WindowsError").innerHTML = ""               
              const token = localStorage.getItem('tap-token');
              const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Authorization: token},
                body: JSON.stringify(values)
              };
              fetch(process.env.REACT_APP_API_URL + '/windowSequence/' + values.id, requestOptions).then(response => response.json())
              .then(body => {
                console.log("body ", body)
                var errorMessage = ""
                if (body.errors && Object.keys(body.errors).length > 0) {                  
                  if (body.errors['windowSequenceName'])
                    errorMessage += body.errors['windowSequenceName']                  
                  if (body.errors['windowsError'])
                    errorMessage += errorMessage.length > 0 ? ", " + body.errors['windowsError'] : body.errors['windowsError']                    
                  notify(errorMessage, {
                      type: "error",
                      undoable: false,
                      messageArgs: { resource: 'put' }
                  })
                } else if (body.error) {
                    toast.dismiss()
                    errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body && body.error && body.error.message ? body.error.message : "Internal Server Error"}</div></div>
                    toast.error(errorMessage, {
                      position: toast.POSITION.BOTTOM_CENTER,
                      closeButton: true,
                      autoClose: 5000
                    });
                } else {                   
                  redirect('/windowSequence');
                  notify("Windowing Sequence Saved", {
                      undoable: false,
                      messageArgs: { resource: 'put' }
                  })
                  return true
                }               
              }).catch(({ status, statusText, headers, body }) => {
                  console.log("Caught status status ", status);
                  console.log("Caught status statusText ", statusText);
                  console.log("Caught status headers ", headers);
                  console.log("Caught status body ", body);
              })
           } catch (error) {
               console.log("Caught error ", error.body.errors)
               if (error.body.errors) {
                  if (error.body.errors.windowsError)
                    document.getElementById("WindowsError").innerHTML = error.body.errors.windowsError
               }
               return error.body.errors;
           } finally {
             console.log("inside finally ")
             return
           }
       },
       [notify, redirect],
  );

  const validateSpecialCharacters = (name) => {
    const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/
    if (specialChars.test(name)) {
        return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
    }
    return undefined;
  }

  return (
    <Edit undoable={false} title={<EditWindowingSequenceTitle />}  {...props}>
      <SimpleForm {...props} save={save} toolbar={<CustomToolbar />}>
        <WindowingSequenceForm {...props} />
        <div id="WindowsError" style={{ color: '#f44336',  width: '98%', paddingLeft: '1%', fontSize: '0.75rem;' }}></div>
        <Box display="flex" justifyContent="space-between" style={{ width: '98%', paddingLeft: '1%', float: 'left' }} id="WindowsArray">
          <ArrayInput source="windows" label="Windows">
            <SimpleFormIterator disableReordering addButton={<AddButton  />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} />}>
                <FormDataConsumer>
                    {( {
                           formData, // The whole form data
                           scopedFormData, // The data for this item of the ArrayInput
                           getSource, // A function to get the valid source inside an ArrayInput
                           ...rest

                       }  ) => {
                    const index = getWindowArrayIndex(getSource(''));
                    if(index === 0) {
                        return (
                            <>
                                <NumberInput min="0" label="Window Order" source={getSource('windowOrder')}
                                             initialValue={index} style={{display:'none'}} />
                                <TextInput style={{display: "none"}} label="id" source={getSource('id')}/>
                                <TextInput style={{display: "none"}} label="windowSequenceId"
                                           source={getSource('windowSequenceId')}/>
                                <ReferenceInput perPage={100} label="Name" reference="lists/transactionalWindows"
                                                source={getSource('windowId')} validate={required()}>
                                    <SelectInput optionText="label"/>
                                </ReferenceInput>
                                <div style={{ height: '68px'}} > </div>
                                <SelectInput id={'windowStartDate_enabled' + index}
                                             resettable
                                             perPage={100}
                                             label="Window Start Date"
                                             choices ={windowStartDates}
                                             source={getSource('windowStartDate')}
                                             validate={[required(), sameWindowStartDateOnDiffWindow(scopedFormData, formData, index)]}>
                                    <SelectInput optionText="label"/>
                                </SelectInput>
                                <NumberInput min="0" label="Duration" source={getSource('windowDuration')}
                                             validate={[required(), validateSpecialCharacters]}/>
                                <SelectInput label="Duration Unit" source={getSource('windowDurationUnit')}
                                             choices={durationUnits} validate={required()} resettable/>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <NumberInput min="0" label="Window Order" source={getSource('windowOrder')}
                                             initialValue={index} style={{display:'none'}} />
                                <TextInput style={{display: "none"}} label="id" source={getSource('id')}/>
                                <TextInput style={{display: "none"}} label="windowSequenceId"
                                           source={getSource('windowSequenceId')}/>
                                <ReferenceInput
                                        perPage={100}
                                        label="Name"
                                        reference="lists/transactionalWindows"
                                        source={getSource('windowId')}
                                        validate={required()}>
                                    <SelectInput optionText="label"/>
                                </ReferenceInput>
                                <BooleanInput
                                    id="previousWindowPlusOneStart"
                                    source={getSource('previousWindowPlusOneStart')}
                                    label="Previous Window +1 Start"
                                    initialValue={true}
                                    defaultValue={true}
                                    onChange={async (value) => {
                                        await trigger(["previousWindowPlusOneStart", "windowStartDate_enabled" + index]);
                                    }}/>
                                <SelectInput id={'windowStartDate_enabled' + index}
                                             perPage={100} label="Window Start Date"
                                             resettable
                                             choices ={windowStartDates}
                                             allowNull={true}
                                             source={getSource('windowStartDate')}
                                             onChange={async () => {
                                                     await trigger(["previousWindowPlusOneStart", "windowStartDate_enabled" + index]);
                                             }}
                                             validate={[requiredIfNonPreviousWindowPlusOne(scopedFormData, formData, index), sameWindowStartDateOnDiffWindow(scopedFormData, formData, index)]}
                                             initialValue={null}>
                                    <SelectInput optionText="label" />
                                </SelectInput>
                                <NumberInput min="0" label="Duration" source={getSource('windowDuration')}
                                             validate={[required(), validateSpecialCharacters]}/>
                                <SelectInput label="Duration Unit" source={getSource('windowDurationUnit')}
                                             choices={durationUnits} validate={required()} resettable/>
                            </>
                        );
                    }
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
        <Box display="flex" justifyContent="center" style={{ width: '100%', clear: 'both' }}>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export const AddButton = props => (
  <Button {...props} style={{ cursor: 'pointer', color: 'green'}} label="Window" margin="normal">
    <AddIcon label="Window" style={{ cursor: 'pointer', color: 'green'}}  />
  </Button>
);


export const WindowingSequenceCreate = props => {

  const { trigger} = useForm();

  const notify = useNotify();
  const redirect = useRedirect();


  const save = useCallback(
       async (values) => {
           try {
              document.getElementById("WindowsError").innerHTML = ""              
              const token = localStorage.getItem('tap-token');
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: token},
                body: JSON.stringify(values)
              };
              fetch(process.env.REACT_APP_API_URL + '/windowSequence', requestOptions).then(response => response.json())
              .then(body => {
                console.log("body ", body)
                var errorMessage = ""
                if (body.errors && Object.keys(body.errors).length > 0) {                  
                  if (body.errors['windowSequenceName'])
                    errorMessage += body.errors['windowSequenceName']                  
                  if (body.errors['windowsError'])
                    errorMessage += errorMessage.length > 0 ? ", " + body.errors['windowsError'] : body.errors['windowsError']                    
                  notify(errorMessage, {
                       type: "error",
                       undoable: false,
                       messageArgs: { resource: 'post' }
                  })
                } else if (body.error) {
                  toast.dismiss()
                  errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body && body.error && body.error.message ? body.error.message : "Internal Server Error"}</div></div>
                  toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    closeButton: true,
                    autoClose: 5000
                  });
                } else {                  
                  redirect('/windowSequence');
                  notify("Windowing Sequence Created", {
                       undoable: false,
                       messageArgs: { resource: 'post' }
                  })
                  return true
                }               
               }).catch(({ status, statusText, headers, body }) => {
                  console.log("Caught status status ", status);
                  console.log("Caught status statusText ", statusText);
                  console.log("Caught status headers ", headers);
                  console.log("Caught status body ", body);
              })
             } catch (error) {
                 if (error.body.errors) {
                    if (error.body.errors.windowsError)
                      document.getElementById("WindowsError").innerHTML = error.body.errors.windowsError
                 }
                 return error.body.errors;
             } finally {
               return
             }
       },
       [notify, redirect],
  );
  const validateSpecialCharacters = (name) => {
    const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/
    if (specialChars.test(name)) {
        return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
    }
    return undefined;
  }
  return (
      <Create undoable={false} title={<EditWindowingSequenceTitle />} {...props}>
        <SimpleForm {...props} save={save} defaultValue={{ status: "ACTIVE"}}>
          <WindowingSequenceForm {...props} />
          <div id="WindowsError" style={{ color: '#f44336',  width: '98%', paddingLeft: '1%', fontSize: '0.75rem;' }}></div>
          <Box display="flex" justifyContent="space-between"
               style={{width: '98%', paddingLeft: '1%', float: 'left'}} id="WindowsArray">

              <ArrayInput source="windows" label="Windows" defaultValue={[{ windowName: ""}]}>
                  <SimpleFormIterator disableReordering addButton={<AddButton  />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} />}>

                      <FormDataConsumer>
                          {( {
                              formData, // The whole form data
                              scopedFormData, // The data for this item of the ArrayInput
                              getSource, // A function to get the valid source inside an ArrayInput
                              ...rest
                          }  ) => {
                              const index = getWindowArrayIndex(getSource(''));
                              if(index === 0) {
                                  return (
                                      <>
                                          <NumberInput min="0" label="Window Order" source={getSource('windowOrder')}
                                                       initialValue={index} style={{display:'none'}} />
                                          <ReferenceInput perPage={100} label="Name"
                                                          reference="lists/transactionalWindows"
                                                          source={getSource('windowId')} validate={required()}>
                                              <SelectInput optionText="label"/>
                                          </ReferenceInput>
                                          <div style={{ height: '68px'}} > </div>
                                          <SelectInput id={'windowStartDate_enabled' + index}
                                              perPage={100}
                                              label="Window Start Date"
                                              resettable
                                              choices ={windowStartDates}
                                              source={getSource('windowStartDate')}
                                              validate={[required(), sameWindowStartDateOnDiffWindow(scopedFormData, formData, index)]}>
                                                <SelectInput optionText="label"/>
                                          </SelectInput>
                                           <NumberInput min="0" label="Duration" source={getSource('windowDuration')}
                                                       validate={[required(), validateSpecialCharacters]}/>
                                          <SelectInput label="Duration Unit" source={getSource('windowDurationUnit')}
                                                       choices={durationUnits} validate={required()} resettable/>
                                      </>
                                  );
                              } else {
                                  return (
                                      <>
                                          <NumberInput min="0" label="Window Order" source={getSource('windowOrder')}
                                                       initialValue={index} style={{display:'none'}}/>
                                          <ReferenceInput perPage={100} label="Name"
                                                          reference="lists/transactionalWindows"
                                                          source={getSource('windowId')} validate={required()}>
                                              <SelectInput optionText="label"/>
                                          </ReferenceInput>
                                          <BooleanInput
                                              id="previousWindowPlusOneStart"
                                              source={getSource('previousWindowPlusOneStart')}
                                              label="Previous Window +1 Start"
                                              onChange={async (value) => {
                                                  await trigger(["previousWindowPlusOneStart", "windowStartDate_enabled" + index]);
                                              }}
                                              defaultChecked
                                              initialValue={true}/>
                                          <SelectInput id={'windowStartDate_enabled' + index}
                                                       perPage={100} label="Window Start Date"
                                                       allowNull={true}
                                                       choices ={windowStartDates}
                                                       source={getSource('windowStartDate')}
                                                       resettable
                                                       validate={[requiredIfNonPreviousWindowPlusOne(scopedFormData, formData, index), sameWindowStartDateOnDiffWindow(scopedFormData, formData, index)]}
                                                       defaultValue={null}>
                                              <SelectInput optionText="label" />
                                          </SelectInput>

                                          <NumberInput min="0" label="Duration" source={getSource('windowDuration')}
                                                       validate={[required(), validateSpecialCharacters]}/>
                                          <SelectInput label="Duration Unit" source={getSource('windowDurationUnit')}
                                                       choices={durationUnits} validate={required()} resettable/>
                                      </>
                                  );
                              }
                          }}
                        </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>

          </Box>
          <Box display="flex" justifyContent="center" style={{ width: '100%', clear: 'both' }}>
          </Box>
        </SimpleForm>
      </Create>
  );
};
